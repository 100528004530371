<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Subscription'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Subscription Create'"
      :title="'Subscription Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <p class="form-create__label">Price</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'price'"
              :label="'price'"
              :type="'number'"
              :placeholder="'Price'"
              :error-messages="validationErrors['price']"
              isBordered
            />
          </div>
          <p class="form-create__label">Term</p>
          <div class="input-container input-select">
            <MainSelect
              :id="'term'"
              :placeholder="'Choose'"
              :option-list="terms"
              :set-value="formDataToSend"
              :value="formDataToSend.term"
              :error-messages="validationErrors['term']"
            />
          </div>
          <p class="form-create__label">Paddle Id</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'paddle_id'"
              :label="'paddle_id'"
              :type="'text'"
              :placeholder="'Paddle Id'"
              :error-messages="validationErrors['paddle_id']"
              isBordered
            />
          </div>
          <p class="form-create__label">Paddle Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'paddle_name'"
              :label="'paddle_name'"
              :type="'text'"
              :placeholder="'Paddle Name'"
              :error-messages="validationErrors['paddle_name']"
              isBordered
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "SubscriptionCreate",
  metaInfo: {
    title: "Subscription Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        price: "",
        paddle_id: "",
        paddle_name: "",
        term: null,
        status: false,
      },
      terms: [
        {
          name: "Day",
          id: "day",
        },
        {
          name: "Week",
          id: "week",
        },
        {
          name: "Month",
          id: "month",
        },
        {
          name: "Year",
          id: "year",
        },
      ],
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainSelect,
    MainCheckbox,
  },
  mounted() {},
  computed: {
    ...mapState("subscription", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.SUBSCRIPTION_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        price: this.formDataToSend.price,
        paddle_id: this.formDataToSend.paddle_id,
        paddle_name: this.formDataToSend.paddle_name,
        status: this.formDataToSend.status,
      };
      if (this.formDataToSend.term != null) {
        data.term = this.formDataToSend.term.id;
      }
      this.$store.dispatch("subscription/createSubscription", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.SUBSCRIPTION_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
</style>
